import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const AccordionBody = styled.div`
    max-width: 750px;
    &:not(:last-child) {
      margin-bottom: 50px;
    }
`
const AccordionTitle = styled.h3`
  position: relative;
  max-width: 600px;
  margin: 0;
  padding-left: 28px;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  cursor: pointer;
  &:hover:before{
    background: url("data:image/svg+xml,%3Csvg width='8' height='18' viewBox='0 0 13 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 1L10 10L2 19' stroke='%23FF5C00' stroke-width='3'/%3E%3C/svg%3E");
  }
  &:before {
    content: "";
    position: absolute;
    top: 9px;
    left: 0;
    width: 8px;
    height: 18px;
    background: url("data:image/svg+xml,%3Csvg width='8' height='18' viewBox='0 0 13 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 1L10 10L2 19' stroke=${props => props.collapsed ? "'black'" : "'%23FF5C00'"} stroke-width='3'/%3E%3C/svg%3E");
    transform: rotate(${props => props.collapsed ? 0 : 90}deg);
    transition: transform .3s ease;
  }
`
const AccordionSubtitle = styled.div`
  max-height: ${props => props.collapsed ? 0 : props.maxHeihgt}px;
  padding-left: 28px;
  margin: 20px 0 0 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  overflow: hidden;
  transition: max-height .3s ease;

  h2 {
    margin-bottom: 20px;
    font-weight: 600;
  }

  ul {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    padding-left: 20px;
    margin-bottom: 20px;

    li {
      padding-right: 16px;
      margin-bottom: 20px;
    }
  }
`

const Accordion = (props) => {
    const [height, setHeight] = useState(0)
    const [collapsed, setCollapsed] = useState(true);
    const subTitleRef = useRef(null);

    useEffect(()=> {
        setHeight(subTitleRef.current.scrollHeight)
    }, [])

    return (
            <AccordionBody>
                <AccordionTitle collapsed={collapsed} onClick={() => setCollapsed(!collapsed)}>{ props.title }</AccordionTitle>
                <AccordionSubtitle ref={subTitleRef} collapsed={collapsed} maxHeihgt={height} dangerouslySetInnerHTML={ { __html: props.subtitle }}/>
            </AccordionBody>
    )
}

export default Accordion;
