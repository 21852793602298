import React, {useState, useEffect, useRef} from 'react';
import {HelmetDatoCms} from 'gatsby-source-datocms';
import {graphql} from 'gatsby';
import styled from "styled-components";

import {colors} from '../style/style';

import {Title, SubTitle, Outline} from "../components/PageParts";
import Layout from '../components/layout';
import PageHeader from '../components/PageHeader/PageHeader';
import Accordion from '../components/Accordion/Accordion';
import FormContainer from "../components/Form/FormContainer";
import SlideFooter from "../components/SlideFooter";
import MailButton from '../components/MailButton';


export const faqQuery = graphql`{
    allDatoCmsFaq {
        nodes {
            id
            title
            subtitleNode {
                childMarkdownRemark {
                    html
                }
            }
        }
    }
    seo: datoCmsSeo(slug: {eq: "faq"}) {
        meta: seoMetaTags {
            tags
        }
    }
}`;

const FaqSection = styled.section`
  padding: 100px 30px;
  max-width: 1200px;
  margin: 0 auto;
  @media all and (min-width: 1200px) {
    padding: 100px 0;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 100px 0;

  & > h3 {
    margin: 0 220px 0 0;
  }

  div {
    padding: 0;
    background-color: transparent;

    &:after {
      display: none;
    }
  }

  div > h3 {
    display: none;
  }

  button {
    font-family: SF Pro Display;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    text-decoration: underline;
    color: ${colors.dark};

    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }

  @media (max-width: 1200px) {
    & > h3 {
      margin: 0 40px 0 0;
    }
  }

  @media (max-width: 968px) {
    display: block;

    & > h3 {
      margin: 0 0 40px;
    }
  }
`;

const Faq = ({data}) => {
    const {
        allDatoCmsFaq: {nodes: faq},
        seo
    } = data;

    const ref = useRef(null)

    const [buttonPosition, setButtonPosition] = useState(0)

    useEffect(() => {
      if (ref.current) {
        // align button with same value as right margin
        setButtonPosition(parseInt(window.getComputedStyle(ref.current).marginRight))
      }
    }, [])

    return <Layout>
        <HelmetDatoCms bodyAttributes={{ class: 'overflow-auto' }} seo={seo?.meta}/>
        <PageHeader ref={ref}/>

        <FaqSection>
            {buttonPosition > 0 && <MailButton position="fixed" align="right" alignValue={buttonPosition} marginTopFromRelative={180}/>}
            <Title color={colors.dark} style={{marginBottom: '80px'}}>FAQ</Title>
            {faq.map(({id, title, subtitleNode}) =>
                <Accordion key={id} title={title} subtitle={subtitleNode.childMarkdownRemark.html}/>)}
            <Container>
                <SubTitle color={colors.dark} maxWidth={600}>
                    Didn't find the <Outline>answer?</Outline>
                </SubTitle>
                <FormContainer isDepartmentForm={true} />
            </Container>
            
        </FaqSection>

        <SlideFooter/>
    </Layout>
}

export default Faq;
